var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    tw-flex tw-w-full tw-h-auto tw-justify-center tw-items-center\n    lg:tw-flex-row\n  ",staticStyle:{"background":"white"}},[_c('v-col',{staticClass:"tw-hidden lg:tw-flex left-side",attrs:{"sm":"12","lg":"7"}},[_c('v-col',{staticClass:"left-side-body",attrs:{"sm":"12"}},[_c('img',{staticStyle:{"height":"40px","width":"186px"},attrs:{"src":require("@/assets/logos/white.png"),"alt":"logo"}}),_c('label',{staticClass:"buddy"},[_vm._v("Hi Padi!")]),_c('h6',{staticClass:"welcome tw-mt-5"},[_vm._v("Welcome to Nomadicpod.")]),_c('p',{staticClass:"admin tw-mt-5"},[_vm._v("Developer Dashboard")])])],1),_c('v-col',{staticClass:"right-side",attrs:{"sm":"12","lg":"5"}},[_c('v-col',{staticClass:"right-side-body",attrs:{"sm":"12"}},[_c('img',{staticClass:"mb-3 mt-6",staticStyle:{"height":"34px","width":"136px"},attrs:{"src":require("@/assets/logos/blue.png"),"alt":"logo"}}),_c('h6',{staticClass:"signup-header mb-1 mb-md-5"},[_vm._v("Sign Up")]),(_vm.error)?_c('p',{staticClass:"errorx"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.signUpDeveloper)}}},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"placeholder":"Email","solo":"","hide-details":"","type":"email","required":"","autofocus":""},model:{value:(_vm.signupData.email),callback:function ($$v) {_vm.$set(_vm.signupData, "email", $$v)},expression:"signupData.email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"color":"#1E5770","hide-details":"","append-icon":_vm.value ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value ? 'text' : 'password',"solo":"","placeholder":"Password","name":"password","id":"password"},on:{"click:append":function () { return (_vm.value = !_vm.value); }},model:{value:(_vm.signupData.password),callback:function ($$v) {_vm.$set(_vm.signupData, "password", $$v)},expression:"signupData.password"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"tw-pt-4 mb-2",class:classes},[_c('v-text-field',{attrs:{"color":"#1E5770","hide-details":"","append-icon":_vm.value2 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.value2 ? 'text' : 'password',"solo":"","placeholder":"Confirm Password","name":"password","id":"password"},on:{"click:append":function () { return (_vm.value2 = !_vm.value2); }},model:{value:(_vm.signupData.confirmPassword),callback:function ($$v) {_vm.$set(_vm.signupData, "confirmPassword", $$v)},expression:"signupData.confirmPassword"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('base-button',{staticClass:"mt-4",attrs:{"width":"100%","color":"#1E5770","button-text":"Sign Up","loading":_vm.loading,"type":"submit"}}),_c('div',{staticClass:"tw-pt-4"},[_c('h6',{staticClass:"tw-flex already tw-items-center tw-justify-center"},[_vm._v(" Already have an account? "),_c('router-link',{staticStyle:{"color":"#1e5770","cursor":"pointer"},attrs:{"to":{ name: 'Login' }}},[_vm._v(" Login")])],1)])],1)]}}])})],1)],1),_c('modal',{attrs:{"dialog":_vm.dialog,"title":"Thank you","additional-text":"We just sent you a confirmation email.\nPlease check your inbox to confirm your  email.","icon":_vm.check},on:{"close":_vm.toggleModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }